import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';

import { Observable, map } from 'rxjs';
import {
  RC_CMS_APOLLO_CLIENT_NAME,
  TaskTemplate_Feature,
  TaskTemplate_Where,
  TaskTemplates,
} from '../types';

interface TaskTemplatesVars {
  where?: TaskTemplate_Where;
  page?: number;
}

@Injectable()
export class RcCmsTaskTemplatesService {
  private apollo: ApolloBase;

  public readonly features: { [key in TaskTemplate_Feature]: string } = {
    basement: 'Basement',
    garage: 'Garage',
    hot_tub: 'Hot Tub',
    hot_water: 'Hot Water Heater',
    hvac: 'HVAC',
    kitchen: 'Kitchen',
    laundry: 'Laundry',
    lawn: 'Lawn & Irrigation',
    pool: 'Pool',
    structural: 'Structural',
    sump_pump: 'Sump Pump',
    water_softener: 'Water Softener',
  };

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use(RC_CMS_APOLLO_CLIENT_NAME);
  }

  public getTaskTemplates(
    variables?: TaskTemplatesVars,
  ): Observable<TaskTemplates> {
    return this.apollo
      .query<{ TaskTemplates: TaskTemplates }, TaskTemplatesVars>({
        query: gql`
          query TaskTemplatesList($where: TaskTemplate_where, $page: Int = 1) {
            TaskTemplates(where: $where, page: $page) {
              docs {
                id
                title
                description
                thumbnail {
                  url
                }
                createdAt
                categories
                feature
                details
                schedule {
                  recurrence {
                    freq
                    interval
                  }
                  event
                  due
                }
                assignment
                review {
                  required
                  evidence
                  approver
                }
                resources {
                  videos
                  articles
                }
              }
              totalDocs
              page
              totalPages
              hasNextPage
              hasPrevPage
              nextPage
              prevPage
            }
          }
        `,
        variables,
      })
      .pipe(map((result) => result?.data?.TaskTemplates || []));
  }
}
